/**
 * components.js
 * js結合ファイル
 */

'use strict';

/* nodeモジュール */
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

/* 独自モジュール */
require('module_tab_cont.js');       // タブ切替の設定
require('module_slider.js');         // スライダー
require('module_g-nav.js');         // ヘッダーナビゲーション
require('module_text_size.js');         // ヘッダーナビゲーション
require('module_header_height.js');         // ヘッダーの高さ取得
require('module_smooth_scroll.js');       // スムーズスクロール
require('module_sidebar_fixed.js');       // サイドバーの追従設定
require('module_modal.js');       // モーダルウィンドウ設定