/**
 * module_tab_cont.js
 * タブ切替の設定
 */

//タブ切替
$(function() {
  //クリックしたときのファンクションをまとめて指定
  $('.js-tab-cont-list .btn').on("click", function() {

    $parents_li = $(this).parents('li');
    var type = $(this).data("tab");

    $(".js-tab-cont-list .btn").removeClass("active");
    $parents_li.removeClass("active");

    $(this).addClass("active");

    $(".js-tab-cont-target").fadeOut(200).promise().done(function() {
      if(type == "all") {
        $(".js-tab-cont-target").fadeIn(200);
      } else {
        $(".js-tab-cont-target.target-"+ type).fadeIn(200);
      }
    });
  });
});
