/**
 * module_slider.js
 * splide.jsの設定
 */

import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

let splide_class="";

splide_class = ".kv__slider";
if($(splide_class).length > 0) {
  new Splide(splide_class, {
    type: 'fade',
    rewind: true,
    autoplay: true,
    arrows: false,
    interval: 5000, //1枚のスライドをどのぐらい表示させておくか（次のスライド表示まで何秒かけるか。）⇒Swiperのautoplayのdelayに相当
    speed: 2000, //次のスライダーへの移動時間をミリ秒単位で指定（ミリ秒）
  }).mount();

  $(splide_class).find('.splide__slide').css('transform','translateX(0%)');
}

splide_class = ".top__ban__slider";
if($(splide_class).length > 0) {
  new Splide(splide_class, {
    type   : 'loop',
    perPage: 4,
    focus  : 'center',
    autoWidth: true,
    arrows: false,
    pagination: false,
    autoScroll: {
      speed: 2,
    },
  }).mount( {AutoScroll} );
}



// splide_class = ".event__year";
// if($(splide_class).length > 0) {

//   var i = 0;
//   var tar_no = 0;
//   $(".event__year .splide__slide").each(function() {
//       if($(this).find(".link").hasClass("current")) {
//         tar_no = i;
//         new Splide(splide_class, {
//           autoplay: false,
//           perPage: 4,
//           gap: "20px",
//           pagination:false,
//           start:tar_no,
//           breakpoints: {
//             767: {
//               perPage: 2,
//             },
//           }

//         }).mount();

//         return false;
//       }
//       i++;
//   });
// }
