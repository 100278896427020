// スムーズスクロール
$(function() {

  /* 共通関数プラグイン */
  var common = require('lib/common_lib.js');

  // TOPへもどるボタン
  var topBtn = $('#page-top');
  topBtn.hide();
  //スクロールが100に達したらボタン表示
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }

    scrollHeight = $(document).height(); //ドキュメントの高さ
    scrollPosition = $(window).height() + $(window).scrollTop(); //現在地
    footHeight = $("footer").innerHeight(); //footerの高さ（＝止めたい位置）
    if ( scrollHeight - scrollPosition  <= footHeight ) { //ドキュメントの高さと現在地の差がfooterの高さ以下になったら
        if (!common.isSP()) {
          topBtn.css({
              "position":"absolute", //pisitionをabsolute（親：wrapperからの絶対値）に変更
              "bottom": footHeight + 20 //下からfooterの高さ + 20px上げた位置に配置
          });
        } else {
          topBtn.css({
              // "position":"fixed", //pisitionをabsolute（親：wrapperからの絶対値）に変更
              // "bottom": "20px" //下から20px上げた位置に
              "display": "none"
          });
        }
    } else { //それ以外の場合は
        if (!common.isSP()) {
          topBtn.css({
              "position":"fixed", //固定表示
              "bottom": "20px" //下から20px上げた位置に
          });
        } else {
          topBtn.css({
              // "position":"fixed", //固定表示
              // "bottom": "20px" //下から20px上げた位置に
              "display": "none"
        });

        }
    }

  });

  $(window).on('load', function() {
    // アンカーの値取得
    var href = location.hash;
    if (href !== "") {
      $(window).scrollTop(0);

      var margin = 20;
      var head = margin;
      if ($('.gnav__sp__header').css('display') == 'block') {
        head = $(".js__sp__header").outerHeight() + margin;
      } else if ($('.gnav__header').css('display') == 'block') {
        head = $(".js__header").outerHeight() + margin;
      }

      var speed = 400;
      // var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top - head;
      $('body,html').animate({scrollTop:position}, speed, 'swing');
    }
  });
});

$(function(){
  $(document).on("click", 'a[href^="#"]', function() {

    var href = $(this).attr("href");

    // モーダルへのリンクの場合、処理は実施しない
    if (href.indexOf("#modaal") === 0) {
      return false;
    }

    var margin = 20;
    var head = margin;
    if ($('.gnav__sp__header').css('display') == 'block') {
      head = $(".gnav__sp__header").outerHeight() + margin;
    } else if ($('.gnav__header').css('display') == 'block') {
      head = $(".gnav__header").outerHeight() + margin;
    }

    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - head;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});
