/**
 * common_lib.js
 * 共通関数
 */

// モジュールを定義
var CommonLib = function CommonLib() {}


/**********************************************************/
// summary : イニシャルアニメーション判定
// params  : $target:画面に表示される対象オブジェクト
// author  : Ver.1.0.0 2018/08/16 N.Yamashita 新規作成
/**********************************************************/
CommonLib.isAnimationInitial = function($target) {

  $ret = true;

  $value = $(window).scrollTop(); //スクロール値を取得
  $b_height = $(window).height();

  $height = $target.innerHeight();
  $height_herf = $target.innerHeight() / 2;
  $target_t = $target.offset().top; /* ターゲットのTOP座標 */
  $target_m = $target_t + $height_herf; /* ターゲットの中央座標 */
  $target_b = $target_t + $height; /* ターゲットのBOTTOM座標 */

  if (($target_b >= $value && $value + $b_height >= $target_b) ||
    ($value >= $target_t && $target_b >= $value) ||
    ($target_t >= $value && $value + $b_height >= $target_t)) {
    $ret = true;
  } else {
    $ret = false;
  }

  return $ret;
}

/**********************************************************/
// summary : スクロール時アニメーション判定
// params  : $target:画面に表示される対象オブジェクト
// author  : Ver.1.0.0 2018/08/16 N.Yamashita 新規作成
/**********************************************************/
CommonLib.isAnimation = function($target) {

  $ret = true;

  $value = $(window).scrollTop(); //スクロール値を取得
  $b_height = $(window).height();

  $height = $target.innerHeight();
  $height_herf = $target.innerHeight() / 2;
  $target_t = $target.offset().top; /* ターゲットのTOP座標 */
  $target_m = $target_t + $height_herf; /* ターゲットの中央座標 */
  $target_b = $target_t + $height; /* ターゲットのBOTTOM座標 */

  if (($target_b >= $value && $value + $b_height >= $target_b) ||
    ($target_m >= $value && $value + $b_height >= $target_m) ||
    ($target_t >= $value && $value + $b_height >= $target_t)) {
    $ret = true;
  } else {
    $ret = false;
  }

  return $ret;
}

/**********************************************************/
// summary : SP判定
// author  : Ver.1.0.0 2018/08/16 N.Yamashita 新規作成
/**********************************************************/
CommonLib.isSP = function() {
  // $ret = false;

  $ret = true;
  
  if(window.innerWidth > 767) {
    $ret = false;
  }
  
  return $ret;
}

/**********************************************************/
// summary : コンテンツ幅判定
// author  : Ver.1.0.0 2019/06/19 N.Yamashita 新規作成
/**********************************************************/
CommonLib.isContent = function() {
  $ret = true;

  // if(window.innerWidth <= 767) {
  //   return false;
  // }

  if(window.innerWidth > 1260) {
    $ret = false;
  }

  return $ret;
}

/**********************************************************/
// summary : コンテンツ幅判定(サイドメニューなし)
// author  : Ver.1.0.0 2019/06/19 N.Yamashita 新規作成
/**********************************************************/
CommonLib.isContentNoSide = function() {
  $ret = true;

  // if(window.innerWidth <= 767) {
  //   return false;
  // }

  if(window.innerWidth > 1260) {
    $ret = false;
  }

  return $ret;
}

/**********************************************************/
// summary : 最終スクロール判定
// author  : Ver.1.0.0 2019/06/19 N.Yamashita 新規作成
/**********************************************************/
CommonLib.isScrollEnd = function() {
  $ret = false;

  var doch = $(document).innerHeight(); //ページ全体の高さ
  var winh = $(window).innerHeight(); //ウィンドウの高さ
  var bottom = doch - winh; //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
  if (bottom <= $(window).scrollTop()) {
    //一番下までスクロールした時に実行
    $ret = true;
  }

  return $ret;
}

// モジュールを登録
module.exports = CommonLib;
