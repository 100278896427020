/**
 * module_text_size.js
 * テキストサイズの設定
 */

 /* jquery.cookie.jsプラグイン */
 require('lib/jquery.cookie.js');

    // 文字サイズ変更ボタン
    $(function () {
      // クッキー（fontSize）があれば読み込む
      let fz = $.cookie('fontSize');

      if (fz) {
        // サイズ変更ボタンから背景色と文字色のCSSを外す
        $('.bl_sizeBtn').removeClass('fz-active');
        
        // クッキーに保存されたidと一致したら適用
        if (fz == 'fz_md') {// デフォルトサイズ
          $('html').css('font-size', '625%');
          $('#fz_md').addClass('fz-active');
        } else if (fz == 'fz_lg') {
          $('html').css('font-size', '725%');
          $('#fz_lg').addClass('fz-active');
        }
      }
      
      //サイズ変更時にクッキーへ保存
      $('.bl_sizeBtn').click(function () {
        // クリックされたbuttonのidをクッキー（fontSize）に保存（有効期限は7日）
        $.cookie('fontSize', this.id, { expires: 7 });
        
        // サイズ変更ボタンから背景色と文字色のCSSを外す
        $('.bl_sizeBtn').removeClass('fz-active');
        
        // クリックされたbuttonのidと一致したら適用
        if (this.id == 'fz_md') {// デフォルトサイズ
          $('html').css('font-size', '625%');
          $(this).addClass('fz-active');
        } else if (this.id == 'fz_lg') {
          $('html').css('font-size', '700%');
          $(this).addClass('fz-active');
        }
      });
    });