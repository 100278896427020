/**
 * module_modal.js
 * モーダルの設定
 */


$(function() {

  /* modaal.jsプラグイン */
  require('lib/modaal.min.js');

  let open_link = $("[data-lightbox=topicsImg]");

  $('.gallery').modaal({
    type: 'image'
  });
});
