/**
 * module_header_height.js
 * ヘッダーの高さ取得
 */

/* ヘッダーの高さを取得し、本文との間を調整 */
$(function(){
  let mainBlock = $('.c__main');
  let headerHeight = $('.js__header').height();
  if(headerHeight == 0){
    headerHeight = $('.js__sp__header').height();
  };

  $(mainBlock).css('margin-top' , headerHeight);

  $(window).resize(function(){
      let headerChange = $('.js__header').height();
      if(headerChange == 0){
        headerChange = $('.js__sp__header').height();
      };

      $(mainBlock).css('margin-top', headerChange);
  });
});

// $(function(){
//   let windowHeight = $(window).height();

//   $(window).resize(function(){
//     let headerHeight = $('.js__header').height();
//     if(headerHeight == 0){
//       headerHeight = $('.js__sp__header').height();
//     };

//     $('.kv').css(
//       // 'height' , 'calc('+ windowHeight +' - '+ headerHeight +')',
//       'height' , windowHeight
//     ).css(
//       'height' , '-='+headerHeight
//     )
//   });
// });