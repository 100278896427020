/**
 * module_g-nav.js
 * g-navの設定
 */

 /* megamenu.jsプラグイン */
 require('lib/megamenu.js');

 var common = require('lib/common_lib.js');

 // SPメニュー
 $(function() {
   $(".js-spmenu-block, .sp-bk").on("click", function() {
     $(".gnav__sp").toggleClass("sp-active");
     $(".js-spmenu-block").toggleClass("sp-active");
     $(".gnav__sp__header__logo").toggleClass("sp-active");
     $(".sp-bk").toggleClass("sp-active");
     $("#page-top").toggleClass("display-none");

     $(".gnav__sp__header").toggleClass("bg-transparent");
   });
 });

 $(function() {
 $(".gnav__sp .plus").on('click', function() {
   var open_arrow = $(this).parents("li").find(".child");

   if (open_arrow.hasClass("open")) {
     open_arrow.slideUp("slow");
     open_arrow.removeClass("open");
     $(this).removeClass("open");
   } else {
     open_arrow.slideDown("slow");
     open_arrow.addClass("open");
     $(this).addClass("open");
     // 	$(this).css({transform: 'rotate(' + (-90) + 'deg)'});
   }
 });

 // アイコンを囲うタグの指定
 var toggles = $('.gnav__sp .plus');
 //アイコンをクリックでクラスの切替え
 toggles.click( function (event) {
   event.preventDefault();
   $(this).find("i").toggleClass("on");
 });
});

$(function() {
$(".toggle__nav .plus").on('click', function() {
  var open_arrow = $(this).parents("li").find(".child");

  if (open_arrow.hasClass("open")) {
    open_arrow.slideUp("slow");
    open_arrow.removeClass("open");
    $(this).removeClass("open");
  } else {
    open_arrow.slideDown("slow");
    open_arrow.addClass("open");
    $(this).addClass("open");
    // 	$(this).css({transform: 'rotate(' + (-90) + 'deg)'});
  }
});

// アイコンを囲うタグの指定
var toggles = $('.toggle__nav .plus');
//アイコンをクリックでクラスの切替え
toggles.click( function (event) {
  event.preventDefault();
  $(this).find("i").toggleClass("on");
});
});


// SP時のローカルメニューアコーディオン
$(function() {
  var open_arrow = $(".c__service__local__list");
  $(".c__service__local__list__title").on('click', function() {


    if (open_arrow.hasClass("open")) {
      open_arrow.slideUp("slow", function() {
        open_arrow.removeAttr('style');
      });
      open_arrow.removeClass("open");
    } else {
      open_arrow.slideDown("slow");
      open_arrow.addClass("open");
      // 	$(this).css({transform: 'rotate(' + (-90) + 'deg)'});
    }
  });

  $(window).resize(function() {
    if (!common.isSP()) {
      open_arrow.removeAttr('style');
      open_arrow.removeClass("open");
      $('.c__service__local__list__title').find(".plus i").removeClass("on");
    }
  }).resize();

  // アイコンを囲うaタグの指定
  var toggles = $('.c__service__local__list__title');
  //アイコンをクリックでクラスの切替え
  toggles.click( function (event) {
    event.preventDefault();
    $(this).find(".plus i").toggleClass("on");
  });
});


// 検索ボックス
$(function() {
  $(".js-search-box").on('click', function() {
    if($(this).hasClass("on")){
      $(".search__block").removeClass("active");
      $(this).removeClass("on");
    } else {
      $(".search__block").addClass("active");
      $(this).addClass("on");
    };
  });

});

// SP時のスクロール
// $(function(){
//   var state = false;
//   var pos;
//   $(".js-spmenu-block, .sp-bk").click(function(){
//     if (state == false) {
//       pos = $(window).scrollTop();
//       $('body').addClass('fixed').css({'top': -pos});
//       state = true;
//     } else {
//       $('body').removeClass('fixed').css({'top': 0});
//       window.scrollTo(0, pos);
//       state = false;
//     }
//   });
// });