/**
 * module_sidebar_fixed.js
 * サイドバーの追従設定
 */

$(function(){
  // let w = $(window).width();

  // if(w >= 768) {};

  let mainArea = $('.c__main__block');
  let sideBar = $('.js-sidebar');
  if(sideBar.length){    
    let sideBar_position = sideBar.offset().top;
    sideBar.css({
      'position':'sticky',
      // 'top':sideBar_position
      'top':'150px'
    });
  };
});
